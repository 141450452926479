import axiosInstance from "../../../_utils/axiosConfig";
import { paiement } from "../../../API/Paiement/paiement"
//import { useNavigate } from 'react-router-dom'; // Importez useNavigate

export const CashPayement = async (commande, navigate,NamePaiement= null) => { 
  try {
    const commandes = {...commande,NamePaiement}

    let infoPersonelle  = JSON.parse(localStorage.getItem('infoPersonelle'))
    let facture = localStorage.getItem('facture')

    if(infoPersonelle && facture)
      {
            const res = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/updatePaiement/${facture}`,{
              provider:null
          });
          if(res.data.status === 200){
            const status = "success";
            localStorage.setItem('status', status);
            localStorage.removeItem('infoPersonelle')
            localStorage.removeItem('facture')
            navigate('/');
          }
        }
        else{
          const response = await paiement(commandes);
          
        if (response.ok) {
          const status = "success";
          localStorage.setItem('status', status);
          localStorage.setItem('sale_id', response.sale_id);
          navigate('/');  // Rediriger l'utilisateur
        } else {
          alert("Erreur lors du paiement");
        }
    }

   
  } catch (error) {
    console.log("Erreur lors de l'envoi des données du paiement ", error);
  }
};

