import Create from './Create';

const Index = () => {
    let paniers = [];
    const idCurrency = JSON.parse(localStorage.getItem('idCurrency')) || null;
    const countrie = JSON.parse(localStorage.getItem('country')) || null;
    const infoPersonelle = JSON.parse(localStorage.getItem("infoPersonelle"));
    let montant = 0;

    const country = paniers?.filtered?.Country ? paniers.filtered.Country : countrie ? countrie : 'BJ'; 

    if(localStorage.getItem('paiementDetails')){
         paniers = JSON.parse(localStorage.getItem('paiementDetails')) || [];
    }

    if(infoPersonelle){
        infoPersonelle.forEach(element => {
            montant += Math.round(element['invoice_sales_price'] * element['quantity_sales'] + element['Taxe1'] + element['Taxe2'] + element['Taxe3'])
        });
    } 

    const item = {
        id: 2,
        name: 'Bag',
        price: paniers?.totaux ? Math.round(paniers?.totaux) : montant  ,  
    };

    const options = {
        id_currency: idCurrency,
        success_url: window.location.origin,
        cancel_url: window.location.href,
        customer: {
            id: (paniers?.filtered?.Customers_Numbers ? paniers?.filtered?.Customers_Numbers : infoPersonelle[0]?.Customers_Number) ,  
            first_name: (paniers?.filtered?.LastName ? paniers?.filtered?.LastName : infoPersonelle[0]?.customers_lastname),
            last_name: (paniers?.filtered?.Names ? paniers?.filtered?.Names : infoPersonelle[0]?.customers_name),
            email: (paniers?.filtered?.['E-mails'] ? paniers?.filtered?.['E-mails'] : infoPersonelle[0]?.['E-mails']),
            phone: (paniers?.filtered?.Phones ? paniers?.filtered?.Phones :  infoPersonelle[0]?.Phones),
            code_country: country,
        },
        seller_number: 1,
    };

   console.log(paniers,infoPersonelle)
    return <Create item={item} options={options} />;
};

export default Index;
