import { paiement } from "../../../API/Paiement/paiement";

export const LaterPayment = async(commande,navigate,NamePaiement= "Payement Later")=>{
    try{
        const commandes = {...commande,NamePaiement}
        const response = await paiement(commandes);
            if (response.ok) {
              const status = "success";
              localStorage.setItem('status', status);
              localStorage.setItem('sale_id', response.sale_id);
              navigate('/');  // Rediriger l'utilisateur
            } else {
              alert("Erreur lors du paiement");
            }
    }catch(e){
        console.log("Erreur ",e)
    }
}