import table from "../../ASSETS/Icone/table.png"
import React, { useState, useEffect, useContext, useRef } from 'react';
import plat1 from '../../ASSETS/Image/neutre.jpg';
import { IoIosTrash } from "react-icons/io";
import { FaRegCreditCard, FaUser } from "react-icons/fa6";
import Food from './Food'
import Loading from '../../COMPONENTS/Loading';
import Header from '../../COMPONENTS/Header';
import { CartContext } from '../../CartContext';
import { taxe } from '../../API/Taxe/taxe';
import { home } from '../../API/Home/home';
import { toast, ToastContainer } from 'react-toastify';
import { paiement } from '../../API/Paiement/paiement';
import { relevate } from '../../API/Home/relevate';
import PaymentPopup from './Payement';
import { add } from '../../API/Reservation/add';
import { useCustomEventListener } from 'react-custom-events';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdEventBusy } from "react-icons/md";
import { all } from "../../API/Reservation/all";
import { disponibles } from "../../API/Reservation/disponibles";
import axiosInstance from "../../_utils/axiosConfig";
import { useTranslation } from 'react-i18next';


function Home() {
  const formatNumberWithSpaces = (number) => {
    if(!isNaN(number)){
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
  };
   const [person,setPerson] = useState('');
   const [isModalOpen,setIsModalOpen] = useState(false);
   const [isModal,setIsModal] = useState(false);
   const [entre, setEntre] = useState(false);
   const [part,setPart] = useState(true);
   const [device,setDevice] = useState({});
   const [panier,setPanier] = useState(false);
   const [closePanier,setClosePanier] = useState(false);
   const [filteredStarting,setFilteredStarting] = useState([]);
   const [dispos,setDispos] = useState([]);
   const [reservation,setReservation] = useState('');
   const [tab,setTab] = useState('');
   //const [showPopup, setShowPopup] = useState(false);
   const [loading, setLoading] = useState(true);
   const [taxeValue,setTaxeValue] = useState(0);
   const [alltaxe,setAllTaxe] = useState([]);
   const disp = useRef([]);
   const [isModalRecu,setIsModalRecu] = useState(false);
   const [recu,setRecu] = useState([]);
   const [totalTaxe1,setTotalTaxe1] = useState(0);
  const [totalTaxe2,setTotalTaxe2] = useState(0);
  const [totalTaxe3,setTotalTaxe3] = useState(0);
  //const [device,setDevice] = useState('');
 // const [prixu,setPrixu] = useState(0);
 // const [qt,setQt] = useState(0);
  //const [mht,setMht] = useState(0);
  const [mt,setMt] = useState(0);
  const [info,setInfo] = useState({});
  const [identifiantSeller,setIdentifiantSeller] = useState({});
  const [identifiantCustmer,setIdentifiantCustmer] = useState({});
 // const [cusotmer,setCustomer] = useState({});
  //const [seller,setSeller] = useState({})
  const [isPanierDelete,setIsPanierDelete] = useState(false)
  const [isChecked,setIsChecked] = useState(false)
  const [azForm,setAzForm] = useState(false)
   const [etatForm,setEtatForm] = useState(true);
   const [azCustomers,setAzCustomers] = useState([]);
   let infoPersonelle  = JSON.parse(localStorage.getItem('infoPersonelle'))
   let facture = localStorage.getItem('facture')
    const [newform,setNewForm] = useState({
         name: "",
       })

 const { t, i18n } = useTranslation();

  useEffect(() => {
    home().then((response) => {
      if(response)
      {
        setDevice(response.device);
        const id = JSON.stringify(response.device.id_currency);  
        localStorage.setItem('idCurrency', id);
      }
    });
  }, []);

  const { setCart,cart, addToCart, updateQuantity, decreaseQuantity,removeProduct,calculateTotal,clearCart} = useContext(CartContext);    // compteur, nombre de commande

  const handleDecrease = (prod) => {
        decreaseQuantity(prod.id)
    };

    const handleIncrease = (prod) => {
        addToCart(prod)
    };

    const handleChange = (e,prod) => {
      updateQuantity(prod.id, parseInt(e.target.value, 10));
    };
    const remove = (prod)=>{
      removeProduct(prod)
    }

    const location = useLocation();
    const searchReservate = new URLSearchParams(location.search);
    const idReservation = searchReservate.get('q');
    const idCustomer = searchReservate.get('p');
    useEffect(() => {
      if(idReservation && idCustomer){
        setPerson(idReservation);
        axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/getReservation-occuper-by-customer/${idCustomer}`).then((res)=>{
          const newSeatId = { seat_id: res.data.customers.seat_id };
           setDispos((prev)=>[...prev,newSeatId]);
           setTab(res.data.customers.seat_id)
           setReservation(res.data.customers.reservation_id)
           setFilteredStarting((prevcustomer)=>[...prevcustomer,res.data.customers])
        }).catch((err)=>{
          console.log("Erreur lors de l'appel à l'API ",err)
        })
      }
      if (cart.length === 0) {
        if (idReservation) {
          setPerson(idReservation);
          setPanier(true);
        } else {
          setPanier(false);
          setClosePanier(true);
        }
      } else {
        setPanier(true);
        setClosePanier(false);
      }
    }, [cart,location.search]);

     useEffect(()=>{
      if(idReservation===null && idCustomer===null)
      {
          disponibles().then((response)=>{
            setDispos(response.seats)
            disp.current = response.seats
        }).catch((error)=>{
          console.log("erreur lors ",error)
        })
      }
    },[])

    const total = calculateTotal();
    if(localStorage.getItem('status'))
    {
        let saleId = localStorage.getItem('sale_id')
        const cash = async()=> await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/imprime-recu/${saleId}`).then((res)=>{
            if(res.data.status === 200){
                setIsModalRecu(true)
               setRecu(res.data.recu)
               setInfo(res.data.info)
               setIdentifiantCustmer(res.data.identifiantCustmer)
               setIdentifiantSeller(res.data.identifiantSeller)
               //setCustomer(res.data.Customer)
               //setSeller(res.data.Seller)
            }
          }).catch((err)=>{
            console.log("Erreur lors de l'appel a l'API ",err)
          })

          cash()
      toast.success(t('Commande enregistrer avec succès'));
      clearCart([]);
      localStorage.removeItem('status')
      localStorage.removeItem('panier')
      localStorage.removeItem('paiementDetails');
    }
    useEffect(()=>{
          const fetchTaxe =  async()=>{
              try{
                  const response = await taxe();
                  if(response.somme && response.taxes){
                    setTaxeValue(response.somme);
                    setAllTaxe(response.taxes)
                  }
              }catch(error){
                  console.log("Erreur lots de chargement ",error)
              }
          };
          fetchTaxe();
    },[])

    let user = JSON.parse(localStorage.getItem('id'));
   const totaux = total + taxeValue * total;
   const navigate  = useNavigate();
    const handlePaimentSubmit = async () => {
        const paiementDetails = {
           cart,
           total,
           totaux,
           person,
           filtered,
           user,
           alltaxe,
           reservation,
           tab,
        }
        if(cart.length === 0)
        {
          //setIsModal(false);
          return toast.error(t("Votre panier est vide"))
        }
        if(person === '')
        {
          return toast.error(t("Veuillez choisir un client"))
        }
        //if(reservation === ''){
          //return toast.error("Veuillez choisie la reservation ")
        //}
        if(tab === '')
        {
          return toast.error(t("Veuillez choisir la table"))
        }
        const paiementDetailJson = JSON.stringify(paiementDetails)
       // const id = JSON.stringify(idCurrency);
        localStorage.setItem('paiementDetails',paiementDetailJson)
       // localStorage.setItem('idCurrency',id);
        navigate('/payment')
    };
    const onClosed = ()=>{
      setIsModal(false)
    }
    

    useEffect(() => {
      // Récupérer les paramètres de l'URL
      const searchParams = new URLSearchParams(window.location.search);

      // Récupérer les valeurs des paramètres
      const paymentId = searchParams.get('paymentId') || searchParams.get('payment_intent');
      const provider = searchParams.get('provider') || searchParams.get('payment_intent_client_secret');
      const idCurrency = searchParams.get('id_currency') || searchParams.get('redirect_status');

      if (paymentId && !infoPersonelle && !facture) {
        submitForm(paymentId, provider, idCurrency);
      }
    }, []);
    const submitForm = async (paymentId, provider, idCurrency) => {
      try {
        const paiementStore = JSON.parse(localStorage.getItem('paiementDetails'));
        if (!paiementStore) {
          throw new Error("Les détails du paiement ne sont pas disponibles.");
        }

        const paiementDetails = {
          cart: paiementStore.cart,
          total:  paiementStore.total || 0,
          totaux:paiementStore.totaux || 0,
          person: paiementStore.person,
          user: paiementStore.user,
          paymentId,
          provider,
          idCurrency,
          filtered: paiementStore.filtered || null,
          alltaxe:paiementStore.alltaxe,
          reservation:paiementStore.reservation,
          tab:paiementStore.tab ? paiementStore.tab : tab,
          NamePaiement: null
        };
        const response = await paiement(paiementDetails);
       // localStorage.setItem('seatID',response.sale_id);

        if (response.ok) {
          setIsModalRecu(true)
          await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/imprime-recu/${response.sale_id}`).then((res)=>{
            if(res.data.status === 200){
               setRecu(res.data.recu)
               setInfo(res.data.info)
               setIdentifiantCustmer(res.data.identifiantCustmer)
               setIdentifiantSeller(res.data.identifiantSeller)
              // setCustomer(res.data.Customer)
               //setSeller(res.data.Seller)
            }
          }).catch((err)=>{
            console.log("Erreur lors de l'appel a l'API ",err)
          })
          toast.success("Commande enregistrée avec succès");
          localStorage.removeItem('paiementDetails');
          localStorage.removeItem('panier')
          //localStorage.removeItem('idCurrency');
         clearCart([]);
        } else {
          toast.error("Erreur lors du paiement");

        }
      } catch (error) {
        console.error("Erreur lors de l'envoi des données du paiement", error);
      }
    };
    const [releve,setReleve] = useState([]);
    useEffect(() => {
      const fetchReleves = async () => {
          try {
              const sallers = await relevate() ;
              if (sallers.releves) {
                  setReleve(sallers.releves);
              }
          } catch (error) {
              console.log("Erreur lors de la récupération ", error);
          }
      };

      fetchReleves();
  }, []);
  const filtered = releve.find(perso => Number(perso.Customers_Numbers) === Number(person));
   const [formModal,setFormModal] = useState({
    nom: "",
    prenom: "",
    mail: "",
    tel: "",
    compte:""
  });

   const handelFormModalChange = (e)=>{
        const {name,value} = e.target;
        setFormModal((prevFormModalData)=>({
          ...prevFormModalData,
          [name]:value || ''
        }))
    }

  const handeClickP = ()=>{
    setIsModalOpen(true);
    setEtatForm(true)
    setAzForm(false)
  }
  const handleCloseModal = ()=>{
    setIsModalOpen(false)
  }

  const handlEntreChange = (e)=>{
    const {value} = e.target;
    setFormModal((prevFormDatas)=>({
      ...prevFormDatas,
        compte:value
    }));
    setEntre(true);
    setPart(false);
 }
 const handlePartChange = (e)=>{
  const {name,value} = e.target;
  setFormModal((prevFormDatas)=>({
    ...prevFormDatas,
    [name]:value
  }))
  setPart(true);
  setEntre(false)
 }
   useCustomEventListener('customerAdded',(data)=>{
    setReleve((rel)=>[...rel,data])
    setPerson(data['Customers_Numbers'])
   })
  const [errorModal,setErrorModal]  = useState({});
    const handleModalFormSubmit = async (e) => {
      e.preventDefault();

      try {
           await add(formModal);

          setFormModal({
            nom: '',
            prenom: '',
            mail: '',
            tel: '',
            compte: ''
          });

        toast.success('Ajout effectué avec succès');
        setIsModalOpen(false);
        setErrorModal({});
      } catch (error) {
        if(error.response && error.response.data.errors){
            const errors = error.response.data.errors;
            setErrorModal(errors);
            // Afficher chaque erreur individuellement dans le toast
            if (typeof errors === 'string') {
              toast.error(errors);
            } else {
              for (const key in errors) {
                if (errors.hasOwnProperty(key)) {
                  if (Array.isArray(errors[key])) {
                    errors[key].forEach((errMsg) => {
                     // toast.error(errMsg);
                    });
                  } else {
                    //toast.error(errors[key]);
                  }
                }
              }
            }
        }
      }
    };

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await all();

         // console.log(response.reservaty)

          if (response && response.reservaty) {
            const reservationWithTime = response.reservaty.map((reserve) => {
              const [date, time] = reserve.starting_date.split(' ');
              return { ...reserve, starting_date: date, starting_time: time };
            });

            const currentDate = new Date().toISOString().split('T')[0];
            const filteredByCurrentDate = reservationWithTime.filter(reserve =>reserve.starting_date === currentDate);
            const filteredByAttente = filteredByCurrentDate.filter(res => res.status === 1 && res.starting_date === currentDate);
            const filteredByAttenteOccuper = filteredByCurrentDate.filter(res => (res.status === 1 && res.starting_date === currentDate) || (res.status === 2 && res.starting_date === currentDate))

           if(!idReservation && !idCustomer && reservation === '' && person === ''){
            setFilteredStarting(filteredByAttente);
            const updatedD = disp.current.filter(tab =>
              !filteredByAttenteOccuper.some(attente => attente.seat_id === tab.seat_id)
            );
            setDispos(updatedD);
           }
          }
        } catch (error) {
          console.log("Erreur lors de la récupération :", error);
        } finally{
          setLoading(false)
        }

      };

      fetchData();
    }, [disp.current]);

    const handleReservationChange = (e)=>{
      const selectedReservation = e.target.value;
        setReservation(selectedReservation)
        const relatedTable = filteredStarting.find(rel=>String(rel.reservation_id) === String(selectedReservation))
        if(relatedTable){
          setPerson(relatedTable.customers)
          axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/getReservation-attente-by-customer/${selectedReservation}`).then((res)=>{
            const newSeatId = { seat_id: res.data.customers.seat_id };
             setDispos((prev)=>[...prev,newSeatId]);
            setTab(res.data.customers.seat_id )
          }).catch((err)=>{
            console.log("Erreur lors de l'appel à l'API ",err)
          })
        }else{
          setTab('');
          setPerson('')
        }
    }

  const handlePersonChange = (e)=>{
    const personSelected = e.target.value;
    setPerson(personSelected)
    const relatedReservation = filteredStarting.find(filt=>filt.customers === personSelected)
    if(relatedReservation)
      {
        axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/getReservation-attente-by-customer/${relatedReservation.reservation_id}`).then((res)=>{
          const newSeatId = { seat_id: res.data.customers.seat_id };
           setDispos((prev)=>[...prev,newSeatId]);
           setTab(res.data.customers.seat_id )
        }).catch((err)=>{
          console.log("Erreur lors de l'appel à l'API ",err)
        })
          setReservation(relatedReservation.reservation_id)
          //setTab(relatedReservation.seat_id)
      }else{
        setReservation('')
        setTab('')
      }
  }

  const handleTableChange = (e)=>{
      const selectedTable = e.target.value;
      setTab(selectedTable)
      const relatedReservation = filteredStarting.find(filt=>String(filt.seat_id) === String(selectedTable))
      if(relatedReservation)
        {
            setPerson(relatedReservation.customers)
            setReservation(relatedReservation.reservation_id)
        }else{
          setReservation('')
        }
  }

  useEffect(() => {
    let taxe1 = 0, taxe2 = 0, taxe3 = 0, priceu = 0 , qte=0, mts = 0,mhts = 0;
      recu.forEach(detail=>{
        taxe1 += detail['Taxe1']
        taxe2 += detail['Taxe2']
        taxe3 +=detail['Taxe3']
        priceu +=detail['invoice_sales_price']
        qte +=detail['quantity_sales']
        mhts +=detail['invoice_total_sales']
        mts += detail['total_before_taxes'] +  (detail['Taxe1'] + detail['Taxe2'] + detail['Taxe3'])  
      })
      setTotalTaxe1(taxe1)
      setTotalTaxe2(taxe2)
      setTotalTaxe3(taxe3)
     // setPrixu(priceu)
      //setQt(qte)
      //setMht(mhts)
      setMt(mts)
}, [recu]);

const handleCloseModalRecu = ()=>{
    setIsModalRecu(false)
}

 // Fonction pour gérer l'impression et fermer le modal après l'impression
 const handlePrint = () => {
    window.print();  // Lance l'impression
  };

  const handleDeletePanier = ()=>{
     setIsPanierDelete(true)
  }

  const handleDeletePanierFromLocalStorage = ()=>{
    localStorage.removeItem('panier')
    setIsPanierDelete(false)
    setCart('')
  }

  const handleAzCustomerChange = (event) => {
    const checked = event.target.checked; // Obtenir la valeur directement
    setIsChecked(checked); // Mettre à jour l'état

    if (checked) {
        setAzForm(true);
        setEtatForm(false);
    } else {
        setAzForm(false);
        setEtatForm(true);
    }
};

const handleAzFormChange = (e)=>{
  const {name,value} = e.target;
 setNewForm((prevazcustomer)=>({
  ...prevazcustomer,
  [name]:value
 }))
}

useEffect(()=>{
  const handleAzCustomer = async()=>{
    await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/get-az-client`).then((res)=>{
         setAzCustomers(res.data.customers)
    }).catch((err)=>{
      console.log("Erreur lors de l'appel a l'API ",err)
    })
    setEtatForm(false)
    setAzForm(true)
  }
  handleAzCustomer()
},[])

const handleAzFormSubmit = (e)=>{
  e.preventDefault()
  axiosInstance.post(`${process.env.REACT_APP_BASE_URL}/api/store-az-client`,newform).then((res)=>{
      if(res.data.status === 200){
        toast.success(res.data.message)
        setReleve((prev)=>[...prev,res.data.customers])
        setIsModalOpen(false)
        setPerson(res.data.customers.Customers_Numbers)
        setNewForm({
          name:""
        })
      }
  }).catch((err)=>{
    console.log("Erreur lors de l'appel à l'API ",err)
  })
}
const langue = i18n.language === 'fr'? 'fr_FR' : 'en_US'
//console.log("La langue actuelle ", langue)



useEffect(()=>{
    const updateFacture = async()=>{
      if(infoPersonelle && facture){
        // Récupérer les paramètres de l'URL
      const searchParams = new URLSearchParams(window.location.search);

      // Récupérer les valeurs des paramètres
      const provider = searchParams.get('provider') || searchParams.get('payment_intent_client_secret');
        try{
          const res = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/updatePaiement/${facture}`,{
             provider:provider
          });
          if(res.data.status === 200){
           localStorage.removeItem('infoPersonelle')
           localStorage.removeItem('facture')
          }
         }catch(e){
          console.log("Erreur ",e)
         }
      }
    }
  updateFacture()
},[infoPersonelle,facture])


 
  return (
   <div>

     {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (

        <div className='flex flex-row bg-[#F7F7F7] h-screen' >

          {/* Section gauche */}
          <div className='w-2/3 barre_scroll ' >
            {/* Navbar */}
            <Food/>
          </div>

          {/* Section droite */}

           {/* Section droite */}
           <div className='w-1/3 bg-white barre_scroll '>
                <Header></Header>
               <ToastContainer></ToastContainer>
                 { panier &&  (
                      <div >
                       
                         <div className='md:mt-24 xs:mt-96   pl-6 xs:text-lg md:text-xl flex justify-between'>
                              {t('Mes commandes')}   
                              <IoIosTrash onClick={()=>handleDeletePanier()} className='text-2xl text-[#D80E0E]' />
                          </div>
                        {/* Personnes */}
                        <div className='border-2 border-[#C2C2C2] md:w-[290px] sm:w-[110px] rounded-md flex items-center px-3 space-x-2 ml-3'>
                            <FaUser className='text-xl text-[#616161]' />
                            <select
                              className='md:w-[200px] sm:w-[54px] px-2 h-10 outline-none'
                              name="person"
                              value={person}
                              onChange={(e)=>handlePersonChange(e)}
                              >
                              <option value="">{t('choisir le client')}</option>
                              {
                                releve.map((rel,index)=>(
                                  <option
                                     key={index}
                                     value={rel.Customers_Numbers}
                                     >
                                      {rel.Names} {rel.LastName} - {rel.Customers_Numbers}
                                  </option>
                                ))
                              }
                            </select>
                            <p onClick={handeClickP} >
                              <svg className="w-[26px] h-[26px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                <path fillRule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4.243a1 1 0 1 0-2 0V11H7.757a1 1 0 1 0 0 2H11v3.243a1 1 0 1 0 2 0V13h3.243a1 1 0 1 0 0-2H13V7.757Z" clipRule="evenodd" />
                              </svg>
                            </p>
                        </div>
                        <div className='mt-5 border-2 border-[#C2C2C2] md:w-[290px] sm:w-[110px] rounded-md flex items-center px-3 space-x-2 ml-3'>
                             <MdEventBusy  className='text-2xl' />
                              <select
                                  className='sm:w-[80px] md:w-[300px] px-2 h-10 outline-none'
                                  name="reservation"
                                  value={reservation}
                                  onChange={(e)=>handleReservationChange(e)}
                                 >
                              <option value="">{t('choisir la reservation')}</option>
                              {
                                filteredStarting.map((rel,index)=>(
                                  <option
                                     key={index}
                                     value={rel.reservation_id}
                                     >
                                      {rel.Names} {rel.LastName}
                                  </option>
                                ))
                              }
                            </select>
                            {/* <p onClick={handeClickP} >
                              <svg className="w-[26px] h-[26px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                <path fillRule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4.243a1 1 0 1 0-2 0V11H7.757a1 1 0 1 0 0 2H11v3.243a1 1 0 1 0 2 0V13h3.243a1 1 0 1 0 0-2H13V7.757Z" clipRule="evenodd" />
                              </svg>
                            </p> */}
                        </div>

                        <div className='mt-5 border-2 border-[#C2C2C2] sm:w-[110px] md:w-[290px] rounded-md flex items-center px-3 space-x-2 ml-3'>
                            <img src={table} alt='table' className="w-5" />
                            <select
                              className='sm:w-[60px] md:w-[300px] px-2 h-10 outline-none'
                              name="tables"
                              value={tab}
                              onChange={(e)=>handleTableChange(e)}
                              >
                              <option value="">{t('choisir la table')}</option>
                              {
                                dispos.map((rel,index)=>(
                                  <option
                                     key={index}
                                     value={rel.seat_id}
                                     >
                                       table {rel.seat_id}
                                  </option>
                                ))
                              }
                            </select>
                            {/* <p onClick={handeClickP} >
                              <svg className="w-[26px] h-[26px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                <path fillRule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4.243a1 1 0 1 0-2 0V11H7.757a1 1 0 1 0 0 2H11v3.243a1 1 0 1 0 2 0V13h3.243a1 1 0 1 0 0-2H13V7.757Z" clipRule="evenodd" />
                              </svg>
                            </p> */}
                        </div>

                        <div className=' py-6 divide-y-2'>
                            {/* article */}
                            {cart && cart.map((prod, index) => (
                                <div key={index} className='flex xs:flex-col md:flex-row md:items-center md:space-x-4 hover:bg-slate-50 s:px-6 xs:px-3 py-3'>
                                    {/* Nom et categorie de l'article */}
                                    <div>
                                    <img src={prod.img ? `data:image/png;base64,${prod.img}` : plat1} alt='plat1' className='w-[120px] h-[70px]' />
                                    <div className='text-sm font-medium text-[#D19E06]'>{langue === 'fr_FR' ? prod.title : prod.Names}</div>
                                    <div className='text-xs'>{langue === 'fr_FR' ? prod.categorie : prod.en_category}</div>
                                    </div>

                                    {/* quantity */}
                                    <div className='flex items-center text-sm justify-center space-x-4'>
                                    <button
                                        className="focus:outline-none"
                                        onClick={()=>handleDecrease(prod)}
                                    >
                                        -
                                    </button>
                                    <input type='number' value={prod.quantity} onChange={(e)=>handleChange(e,prod)} className='w-[40px] px-2' min="1" />
                                    <button
                                        className="focus:outline-none"
                                        onClick={()=>handleIncrease(prod)}
                                    >
                                        +
                                    </button>
                                    </div>

                                    {/* prix */}
                                    <div className='text-sm font-medium'>{formatNumberWithSpaces(prod.price * prod.quantity)} {device && device.sign}</div>
                                    <IoIosTrash onClick={()=>remove(prod.id)} className='text-2xl text-[#D80E0E]' />
                                </div>
                                ))
                            }
                        </div>

                        {/* Prix total */}
                        <div className='border-t-2 pt-4 barre_scroll2'>
                            <table className='text-lg  '>
                              <tbody>
                              <tr>
                                <td>{t('Total de la commande')}:</td>
                                <td className="ml-8">{formatNumberWithSpaces(total)}</td>
                                <td className="ml-8">
                                   {device && device.sign}
                                </td>
                              </tr>

                                <tr>
                                    <td>{t('Les taxes')}:</td>
                                    <td>{formatNumberWithSpaces(taxeValue * total) }</td>
                                    <td className="ml-60">
                                      {device && device.sign}
                                    </td>
                                </tr>
                                <tr className="font-bold">
                                    <td >{t('TOTAUX')}:</td>
                                    <td>{formatNumberWithSpaces(Math.round(total + taxeValue * total))}</td>
                                    <td>
                                        {device && device.sign}
                                    </td>
                                </tr>
                              </tbody>

                            </table>
                        </div>

                      <div className='flex justify-center my-4'>
                          <button onClick={handlePaimentSubmit}  className='bg-[#D19E06] text-white flex items-center space-x-3 text-xl w-[150px] h-10 justify-center'>
                              <FaRegCreditCard />
                              <p>{t('Paiement')}</p>
                          </button>
                          {/* <Link to='/payment' className='bg-[#D19E06] text-white flex items-center space-x-3 text-xl w-[150px] h-10 justify-center'>
                              <FaRegCreditCard />
                              <p>Paiement</p>
                          </Link> */}
                      </div>
                      </div>
                   )
                 }

                 {closePanier && (
                      <p> {t('Panier vide')} </p>
                  )}
                  {/*
                      {isModal &&
                   <PaymentPopup open={isModal}
                   tel ={filtered.Phones}
                   user_Id={filtered.Customers_Numbers}
                   montant  = {totaux}
                   email  = {filtered['E-mails']}
                   onSuccess={handlePopupSuccess}
                   userPrenomu={filtered.LastName}
                   serNom={filtered.Names}
                   onClose={onClosed}
                   handlCashSubmit={submitForm}
                   />}
                  */} 

            </div>
            {isModalOpen && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white p-6 rounded shadow-lg w-[90%] sm:w-[340px] md:w-[400px]">
                {/* Checkbox */}
                <p className="flex items-center space-x-2 mb-4">
                        <input 
                          onChange={handleAzCustomerChange}  
                          className="w-[18px] h-[18px]" 
                          type="checkbox" 
                          checked={isChecked}
                          id="azcompte"
                        />
                        <label htmlFor="azcompte" className="text-sm">
                         {t("Ce client a un compte AZ")}
                        </label>
                      </p>
                      {etatForm && (
                          <div>
                              <h2 className="text-lg mb-3">{t('Veuillez remplir le formulaire')}</h2>
                              <form onSubmit={handleModalFormSubmit}>
                                {/* Type de compte */}
                                <div className="flex items-center space-x-2">
                                  <label htmlFor="entreprise" className="text-sm">{t("Type")}:</label>
                                  <input
                                    onChange={handelFormModalChange}
                                    onClick={handlEntreChange}
                                    className="w-5 h-5 transform scale-75"
                                    type="radio"
                                    name="compte"
                                    value="webcompany"
                                    checked={formModal.compte === 'webcompany'}
                                    id="entreprise"
                                  />
                                  <label htmlFor="entreprise" className="text-sm">{t("Entreprise")}</label>
                                  <input
                                    onChange={handelFormModalChange}
                                    onClick={handlePartChange}
                                    className="w-5 h-5 transform scale-75"
                                    type="radio"
                                    name="compte"
                                    value="webbyyer"
                                    checked={formModal.compte === 'webbyyer'}
                                    id="particulier"
                                  />
                                  <label htmlFor="particulier" className="text-sm">{t("Particulier")}</label>
                                </div>
                                {errorModal.compte && <p className="text-red-500 text-xs mb-4">{errorModal.compte}</p>}

                                {/* Formulaire Particulier */}
                                {part && (
                                  <>
                                    <div className="mt-2 border-2 border-[#C2C2C2] w-full rounded-md flex items-center px-3">
                                      <input
                                        type="text"
                                        className="w-full px-2 h-10 outline-none"
                                        placeholder={t("Nom")}
                                        name="nom"
                                        value={formModal.nom}
                                        onChange={handelFormModalChange}
                                      />
                                    </div>
                                    {errorModal.nom && <p className="text-red-500 text-xs mb-4">{errorModal.nom}</p>}

                                    <div className="mt-2 border-2 border-[#C2C2C2] w-full rounded-md flex items-center px-3">
                                      <input
                                        type="text"
                                        className="w-full sm:w-[140px] px-2 h-10 outline-none"
                                        placeholder={t("Prénom")}
                                        name="prenom"
                                        value={formModal.prenom}
                                        onChange={handelFormModalChange}
                                      />
                                    </div>
                                    {errorModal.prenom && <p className="text-red-500 text-xs mb-4">{errorModal.prenom}</p>}
                                  </>
                                )}

                                {/* Formulaire Entreprise */}
                                {entre && (
                                  <>
                                    <div className="border-2 border-[#C2C2C2] w-full rounded-md flex items-center px-3">
                                      <input
                                        type="text"
                                        className="w-full px-2 h-10 outline-none"
                                        placeholder={t("Nom de l'entreprise")}
                                        name="nom"
                                        value={formModal.nom}
                                        onChange={handelFormModalChange}
                                      />
                                    </div>
                                    {errorModal.nom && <p className="text-red-500 text-xs mb-4">{errorModal.nom}</p>}
                                  </>
                                )}

                                {/* E-mail et téléphone */}
                                <div className="mt-2 border-2 border-[#C2C2C2] w-full rounded-md flex items-center px-3">
                                  <input
                                    type="text"
                                    className="w-full px-2 h-10 outline-none"
                                    placeholder={t("Adresse e-mail")}
                                    name="mail"
                                    value={formModal.mail}
                                    onChange={handelFormModalChange}
                                  />
                                </div>
                                {errorModal.mail && <p className="text-red-500 text-xs mb-4">{errorModal.mail}</p>}

                                <div className="mt-2 border-2 border-[#C2C2C2] w-full rounded-md flex items-center px-3">
                                  <input
                                    type="text"
                                    className="w-full px-2 h-10 outline-none"
                                    placeholder={t("Téléphone")}
                                    name="tel"
                                    value={formModal.tel}
                                    onChange={handelFormModalChange}
                                  />
                                </div>
                                {errorModal.tel && <p className="text-red-500 text-xs mb-4">{errorModal.tel}</p>}

                                {/* Bouton Valider */}
                                <button type="submit" className="bg-[#D19E06] text-white w-full h-10 rounded-md mt-3">
                                  {t('Valider')}
                                </button>
                              </form>
                          </div>
                      )}
                        
                       {azForm && (
                          <div>
                              <form onSubmit={handleAzFormSubmit} >
                                {/* E-mail et téléphone */}
                                <div className="mb-3 border-2 border-[#C2C2C2] w-full rounded-md flex items-center px-3 mb-3">
                                <select
                                className="w-full px-2 h-10 outline-none"
                                name="name"
                                value={newform.name}
                                onChange={handleAzFormChange}
                              >
                                <option>{t('Veuillez choisir le client')}</option>
                                {azCustomers.map((custome, index) => (
                                  <option key={index} value={custome.Customers_Numbers}>
                                    {custome.LastName} {custome.Names}
                                  </option>
                                ))}
                              </select>
                                </div>
                                {/* Bouton Valider */}
                                <button type="submit" className="bg-[#D19E06] text-white w-full h-10 rounded-md mt-3">
                                  {t('Valider')}
                                </button>
                              </form>
                          </div>
                      )}

                      

                {/* Annuler button */}
                <div className="flex justify-end mt-4 space-x-4">
                  <button
                    className="bg-gray-500 text-white px-4 py-2 rounded"
                    onClick={handleCloseModal}
                  >
                   {t("Annuler")}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

      )}

        {isModalRecu && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white p-6 rounded shadow-lg w-[50%] sm:w-[400px] md:w-[400px] max-h-screen overflow-y-auto">
              <>
                <div className="items-center justify-center">
                  <div className="text-center">
                    <div className="flex items-center justify-center">
                            <p>
                              <img className="w-10 h-10 rounded-full object-cover"  src={`data:image/png;base64,${info.logo}`} alt="Logo"/>
                            </p>
                            <h2 className="ml-4 text-center text-xl bg-dark-600">
                              <strong>{info.name}</strong>
                            </h2>
                      </div>
                      <p className="mb-4">{info.Type},{info.address}  {info.phone} / {info.email} </p>
                  </div>
                </div>
                  <div className="">
                  <div className="">
                      {/* Première colonne */}
                      <div className="flex-grow">
                      {recu[0] && recu[0].Customers ? (
                          <>
                          <p>
                              <span className="inline ">{t("Table")}:</span> 
                              <span className="flex-grow w-1/3 border-b border-dotted mx-2">-----------------------</span>
                              <span className="inline float-right">{recu[0].Seat_id}</span>
                          </p>
                          <p>
                              <span className="inline ">{t("Nom du Client")}:</span> 
                              <span className="flex-grow w-1/3 border-b border-dotted mx-2">----------------</span>
                              <span className="inline float-right">{recu[0].Customers}</span>
                          </p>
                          <p>
                              <span className="inline ">{t("Identification")}:</span>
                              <span className="flex-grow w-1/3 border-b border-dotted mx-2">--------------</span>
                              <span className="inline float-right">{identifiantCustmer}</span>
                          </p>

                          </>
                      ) : (
                          <p>{t('Client introuvable')}</p>
                      )}
                      </div>

                      {/* Deuxième colonne */}
                      <div className="flex-grow ">
                      {recu[0] && recu[0].employees_names ? (
                          <>
                          <p>
                              <span  className="inline">{t("Vendeur")}:</span>
                              <span className="flex-grow w-1/3 border-b border-dotted mx-2">----------------</span>
                              <span className="inline float-right">{recu[0].employees_names} {recu[0].employees_firstname || ''}</span>
                          </p>
                          <p>
                              <span className="inline">{t("Identification")}:</span>
                              <span className="flex-grow w-1/3 border-b border-dotted mx-2">--------------</span>
                              <span className="inline float-right">{identifiantSeller}</span>
                          </p>
                    
                  
                          </>
                      ) : (
                          <p>{t("Employe introuvable")}</p>
                      )}
                      </div>

                      {/* Deuxième colonne */}
                      <div className="flex-grow">
                          {recu[0] && recu[0].Dates ? (
                              <>
                                  <span className="inline ">{t("Date & Heure")}:</span>
                                  <span className="flex-grow w-1/3 border-b border-dotted mx-2">---------</span>
                                  <span className="inline float-right">
                                      {recu[0].Dates || '24/11/2024'}
                                          
                                      <span className="ml-2">
                                          { new Date().getHours().toString().padStart(2,'0')}
                                                      :
                                          { new Date().getMinutes().toString().padStart(2,'0')}
                                                      :
                                          { new Date().getSeconds().toString().padStart(2,'0')}

                                      </span>           
                                    
                                  </span>
                              </>
                          ) : (
                              <p>{t("Employe introuvable")}</p>
                          )}
                      </div>
                  </div>
                  </div>

                  {/* Table pour les détails */}
                  <div className="overflow-x-auto flex justify-start">
                  <table className="table-auto w-full md:w-[350px] text-center border-collapse my-2">
                      <thead>
                          <tr>
                          <th className="py-2 text-left">{t("Article")}</th>
                          <th className="py-2 text-right">{t("Qte")}</th>
                          <th className="py-2 text-right">{t("Prix")}</th>
                          <th className="py-2 text-right">{t("Montant")}</th>
                          </tr>
                      </thead>
                      <tbody>
                          {recu.map((rec, index) => (
                          <tr key={index}>
                              <td className="text-left">{langue === 'fr_FR' ? rec.items_fr_name : rec.items_names}</td>
                              <td className="text-center">{rec.quantity_sales}</td>
                              <td className="text-right">{rec.invoice_sales_price}</td>
                              <td className="text-right">{rec.total_before_taxes}</td>
                          </tr>
                          ))}
                          <tr>
                            <td colSpan="3" className="text-right font-bold py-2">{recu[0]?.amount_paid === 0 ? t("Total à payer") : t("Total")}:</td>
                            <td className="text-right font-bold py-2">{Math.round(mt)}{device && device.sign}</td>
                          </tr>

                          {recu[0] && recu[0].Taxe1_Name && (
                          <tr>
                              <td colSpan="3" className="text-right font-bold py-2">{t("Taxe: ") + recu[0].Taxe1_Name}</td>
                              <td className="text-right py-2">{recu[0].Taxe1}</td>
                          </tr>
                          )}
                          {recu[0] && recu[0].Taxe2_Name && (
                          <tr>
                              <td colSpan="3" className="text-right font-bold py-2">{t("Taxe: ") + recu[0].Taxe2_Name}</td>
                              <td className="text-right py-2">{recu[0].Taxe2}</td>
                          </tr>
                          )}
                           {recu[0] && recu[0].Taxe3_Name && (
                          <tr>
                              <td colSpan="3" className="text-right font-bold py-2">{t("Taxe: ") + recu[0].Taxe3_Name}</td>
                              <td className="text-right py-2">{recu[0].Taxe3}</td>
                          </tr>
                          )}
                           <tr>
                              <td colSpan="3" className="text-right font-bold py-2">{t("Total des Taxes")}:</td>
                              <td className="text-right py-2">{totalTaxe1 + totalTaxe2 + totalTaxe3} {device && device.sign}</td>
                          </tr>
                      </tbody>
                      
                  </table>
                
                  </div>
                  <p className="text-center">{t("Transaction en success")}</p>
                  {/* Bouton d'impression */}
                  <div className="flex justify-center mt-4">
                      <button
                          onClick={handlePrint}
                          className="bg-[#4CAF50] text-white px-6 py-2 rounded hover:bg-[#45a049]">
                          {t("Imprimer")}
                      </button>
                      <button
                          onClick={() =>handleCloseModalRecu()}
                          className="bg-[#ff0000] text-white ml-4 px-6 py-2 rounded hover:bg-[#cc0000]">
                          {t("Ignorer")}
                    </button>
                  </div>

              </>
              </div>
          </div>
        )}

          {isPanierDelete && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white p-6 rounded shadow-lg w-[50%] sm:w-[400px] md:w-[400px] max-h-screen overflow-y-auto">
                  <>

                    <div className="items-center justify-center">
                    <div className="text-center">
                      <div className="flex items-center justify-center">
                             <h4>{t('Voulez-vous vider  votre panier?')}</h4>
                        </div>
                    </div>
                  </div>
                  
                    {/* Bouton d'impression */}
                    <div className="flex justify-center mt-4">
                        <button
                            onClick={handleDeletePanierFromLocalStorage}
                            className="bg-[#4CAF50] text-white px-6 py-2 rounded hover:bg-[#45a049]">
                           {t('Oui')}
                        </button>
                        <button
                            onClick={() =>setIsPanierDelete(false)}
                            className="bg-[#ff0000] text-white ml-4 px-6 py-2 rounded hover:bg-[#cc0000]">
                            {t("Non")}
                      </button>
                    </div>

                </>
                </div>
            </div>
          )}
   </div>
  )
}

export default Home