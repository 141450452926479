import React, { useState } from 'react';
import Fedapay from './Providers/Fedapay';
import PayTech from './Providers/PayTech';
import Stripe from './Providers/Stripe/Stripe';
import AZPAY from './Providers/AZPAY';
import {CashPayement} from './Providers/Cash';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LaterPayment } from './Providers/Later';

const Create = ({item, options}) => {
    const [showStripe, setShowStripe] = useState(false);
    const [showAZPAY, setShowAZPAY] = useState(false);
    const paniers = JSON.parse(localStorage.getItem('paiementDetails'))
     const { t, i18n } = useTranslation();
     let infoPersonelle  = JSON.parse(localStorage.getItem('infoPersonelle'))
    //let facture = localStorage.getItem('facture')
    
    let navigate = useNavigate();
    if(!item)
        item = {
            'id': 2,
            'name': 'Bag',
            'price': 12000,
        }
    if(!options)
        options = {
            id_currency: 3,
            success_url: window.location.origin + '/success',
            cancel_url: window.location.href,
            customer: {
                id: 106,
                first_name: 'Jean',
                last_name: 'DUPONT',
                email: 'jeandupont@gmail.com',
                phone: '64000001',
                code_country: 'CI'
            },
            seller_number: 1
        }
    return (
                showStripe 
                ?
                    <Stripe item={item} options={options} />
                :
                <div className='border rounded-lg p-3 w-11/12 lg:w-1/2 mx-auto bg-white shadow-lg mt-4'>
                    {
                        showAZPAY
                        ?
                            <AZPAY item={item} options={options} />
                        :
                        <>
                            <h1 className='text-xl text-center font-bold mb-4 shadow-sm'>{t("Choisir un mode de paiement")}</h1>
                            {
                                options.customer.code_country === 'CI' || options.customer.code_country === 'SN' 
                                ?
                                    <PayTech item={item} options={options} />
                                :
                                    <Fedapay item={item} options={options} />
                            }
                            <button onClick={() => setShowStripe(true) } className='text-white bg-[#D19E06] hover:bg-black px-3 py-1 rounded my-4 text-center w-3/4 md:w-1/2 mx-auto block'>
                               {t("Carte Bancaire")}
                            </button>
                            <button onClick={() => setShowAZPAY(true) } className='text-white bg-[#D19E06] hover:bg-black px-3 py-1 rounded my-4 text-center w-3/4 md:w-1/2 mx-auto block'>
                                {t("AZ PAY")}
                            </button>
                            <button onClick={() =>CashPayement(paniers,navigate) } className='text-white bg-[#D19E06] hover:bg-black px-3 py-1 rounded my-4 text-center w-3/4 md:w-1/2 mx-auto block'>
                                {t("Cash")}
                            </button>
                            {
                                !infoPersonelle && (
                                    <button onClick={() =>LaterPayment(paniers,navigate) } className='text-white bg-[#D19E06] hover:bg-black px-3 py-1 rounded my-4 text-center w-3/4 md:w-1/2 mx-auto block'>
                                       {t("Payer plus tard")}
                                   </button>
                                )
                            }
                           
                            
                        </>
                    }
                </div>
                    
    );
};

export default Create;